@font-face {
  font-family: "amatic";
  src: url("/fonts/AmaticSC-Regular.ttf");
}

@import url("https://use.typekit.net/rgt5inz.css");


h1, h2, h3 {
  font-family: amatic;

}

h1 {
  font-size: 50px;
  font-weight: normal;
}

h2 {
  font-size: 40px;
  font-weight: normal;
}


body {
  margin: 0;
  font-family: "futura-pt";
  min-height: 120vh;
  font-size: 19px;
}



html {
  scroll-behavior: smooth;
}
html, body {
  margin: 0;
  padding: 0;

}

body {
  background-image: url("https://content.ps30.de/images/content/2000x/egg.homepage.chalkboard.jpg");
  background-repeat: repeat;
  background-color: none;
}

.loading-background {
  display: none!important;
}

#root {
  overflow: hidden;
}

#lz_overlay_chat, #lz_eye_catcher {
  right: auto!important;
  left: 0px;
  margin-left: 50px!important;
}


.slick-dots {
  position: initial;
  bottom: auto;
  li {
    margin: 0 5px;
    button {
      background: transparent;
    }
    button:before {
      font-size: 15px;
      color: white;
      opacity: 1;
    }
    &.slick-active {
      button:before {
        color: #4c4c4c;
        opacity: 1;
      }
    }
  }
}
